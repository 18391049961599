import {
  Logo,
  StyledAnchorTag,
  StyledAppBar,
  StyledDivButton,
  StyledRightElements,
} from "./styles"
import BhiveLogo from "../../assets/BhiveLogo.svg"
import { CENTERS } from "../../constants/path"
import CallIcon from "@mui/icons-material/Call"
import LogoutIcon from "@mui/icons-material/Logout"
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import Cookies from "js-cookie"
import { useSearchParams } from "react-router-dom"
import { URLParams } from "../../constants/enums"
import { useLogoutUserMutation } from "../../apis/userApi"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUser, setUserCity } from "../../slices/userSlice"
import { useEffect } from "react"
import theme from "../../theme"

const Header = () => {
  const [, setSearchParams] = useSearchParams()
  const { userData, userCity } = useAppSelector(selectUser)
  const [logoutUser] = useLogoutUserMutation()
  const dispatch = useAppDispatch()

  const handleLogout = async () => {
    try {
      await logoutUser(null)
      setSearchParams((params) => {
        params.set(URLParams.ACTIVE_STATE, "0")
        return params
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setUserCity({ city: event.target.value }))
    Cookies.set("city", event.target.value, { expires: 7 })
  }

  useEffect(() => {
    dispatch(setUserCity({ city: Cookies.get("city") || "Bengaluru" }))
  }, [dispatch])

  return (
    <StyledAppBar elevation={0}>
      <Logo
        src={BhiveLogo}
        alt="BhiveLogo"
        onClick={() => window.open(CENTERS, "_self")}
      />

      <StyledRightElements>
        <Box sx={{ minWidth: 80, width: "10vw" }}>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ color: theme.palette.primary.main }}
              id="demo-select-small-label"
            >
              City
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={userCity}
              label="City"
              sx={{
                height: 42,
                ".MuiOutlinedInput-notchedOutline": {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Bengaluru"}>Bengaluru</MenuItem>
              <MenuItem value={"Mumbai"}>Mumbai</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledAnchorTag href="tel:+919538677774">
          <IconButton aria-label="call-icon" color="primary">
            <CallIcon />
          </IconButton>
        </StyledAnchorTag>
        {userData?.accessToken && (
          <StyledDivButton>
            <IconButton
              onClick={() => handleLogout()}
              aria-label="logout-icon"
              color="primary"
            >
              <LogoutIcon />
            </IconButton>
          </StyledDivButton>
        )}
      </StyledRightElements>
    </StyledAppBar>
  )
}

export default Header
